.viewproject {
text-align: center;
margin-bottom: 20px;
}
.viewproject .ant-card-body {
    box-shadow: 0 3px 20px rgba(0,0,0,.04);
}
.viewproject .ant-card-bordered {
    border: none;
}
.viewproject .ant-checkbox-wrapper {
    position: absolute;
    left: 11px;
    top: 11px;
}
.viewproject .anticon-delete {
    position: absolute;
    right: 11px;
    top: 11px;
    
}
.viewproject .anticon-delete svg {
    font-size: 14px;
}
.viewproject .ant-typography {
    font-size: 16px;
    margin:10px 0 0 0;
}
.viewproject em {
    color:#d8d7d7
}
.viewproject svg {
font-size: 28px;;
}