@import url("https://fonts.googleapis.com/css?family=IBM+Plex+Sans:200,300,400,400i,500,500i,600,600i,700,700i");
body {
  font-family: "IBM Plex Sans", sans-serif !important;
  color: #232a31 !important;
}
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-float infinite 3s ease-in-out;
  }
}

.logo {
  height: 32px;
  background: rgba(255, 255, 255, 0.2);
  margin: 16px;
}

.App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
}

.App-link {
  color: rgb(112, 76, 182);
}

@keyframes App-logo-float {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(10px);
  }
  100% {
    transform: translateY(0px);
  }
}
.mt-30 {
  margin-top: 30px;
}
.ant-table {
  color: #212529;
}
.ant-table.ant-table-bordered
  > .ant-table-container
  > .ant-table-content
  > table
  > thead
  > tr
  > th,
.ant-table.ant-table-bordered
  > .ant-table-container
  > .ant-table-header
  > table
  > thead
  > tr
  > th,
.ant-table.ant-table-bordered
  > .ant-table-container
  > .ant-table-body
  > table
  > thead
  > tr
  > th,
.ant-table.ant-table-bordered
  > .ant-table-container
  > .ant-table-content
  > table
  > tbody
  > tr
  > td,
.ant-table.ant-table-bordered
  > .ant-table-container
  > .ant-table-header
  > table
  > tbody
  > tr
  > td,
.ant-table.ant-table-bordered
  > .ant-table-container
  > .ant-table-body
  > table
  > tbody
  > tr
  > td,
.ant-table.ant-table-bordered
  > .ant-table-container
  > .ant-table-content
  > table
  > tfoot
  > tr
  > th,
.ant-table.ant-table-bordered
  > .ant-table-container
  > .ant-table-header
  > table
  > tfoot
  > tr
  > th,
.ant-table.ant-table-bordered
  > .ant-table-container
  > .ant-table-body
  > table
  > tfoot
  > tr
  > th,
.ant-table.ant-table-bordered
  > .ant-table-container
  > .ant-table-content
  > table
  > tfoot
  > tr
  > td,
.ant-table.ant-table-bordered
  > .ant-table-container
  > .ant-table-header
  > table
  > tfoot
  > tr
  > td,
.ant-table.ant-table-bordered
  > .ant-table-container
  > .ant-table-body
  > table
  > tfoot
  > tr
  > td {
  border-right: 1px solid #eceff3;
}
.ant-table-tbody > tr > td {
  border-bottom: 1px solid #eceff3;
}

.ant-input-number {
  width: 100% !important;
}

table {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

td,
th {
  border: 1px solid #dddddd;
  text-align: left;
  padding: 8px;
}

th {
  background-color: #fafafa;
}

.ant-table.ant-table-small {
  font-size: 12px !important;
}

.error {
  color: #ff4d4f;
}
.ant-btn-primary {
  background: #f37523;
    border-color: #f37523;
}
.ant-table-thead > tr > th, .ant-table-small .ant-table-thead > tr > th {
      background: #f37523;
          color:#fff;
          border-bottom: 1px solid #f49e66;
            border: 1px solid #f49e66 !important;
}
.ant-form-item {
      margin-bottom: 14px;
}
.ant-input, .ant-input-number-input {
      padding: 4px 11px;
       height: 40px;
}
.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  padding: 4px 11px;
  height: 40px;
}
.text-right {
  text-align: right;
}
.ant-table-content p {
  margin-bottom: 0;
}