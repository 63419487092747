body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

#components-layout-demo-custom-trigger .trigger {
  font-size: 18px;
  line-height: 64px;
  padding: 0 24px;
  cursor: pointer;
  transition: color 0.3s;
}

#components-layout-demo-custom-trigger .trigger:hover {
  color: #1890ff;
}

#components-layout-demo-custom-trigger .logo {
  height: 32px;
  background: rgba(255, 255, 255, 0.2);
  margin: 16px;
}

.site-layout .site-layout-background {
  background: #fff;
}
.ht-100v {
    height: 100vh;
}
.login-bg {
        background: url(bg.jpg);
    background-position: center;
    background-size: cover;
    position: relative;
}
.login-bg-overlay {
    background: rgba(0,0,0,0.4);
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 999;
    display: block;
}
.d-flex {
    display: -ms-flexbox!important;
    display: flex!important;
}
.align-self-center {
    -ms-flex-item-align: center!important;
    align-self: center!important;
}
.tx-20 {
    font-size: 20px;
}
.tx-gray-100 {
    color: #f8f9fa;
}

.tx-semibold {
    font-weight: 600;
}
.pd-t-50 {
    padding-top: 50px;
}
.tx-gray-200 {
    color: #e9ecef;
}
@media (min-width: 768px) {
.pd-md-x-100 {
    padding-left: 200px;
    padding-right: 200px;
}
}
.pd-y-15 {
    padding-top: 15px;
    padding-bottom: 15px;
}
.text-center {
    text-align: center!important;
}
.bg-light {
    background-color: #f8f9fa !important;
}
.align-items-center {
    -ms-flex-align: center!important;
    align-items: center!important;
}

.justify-content-center {
    -ms-flex-pack: center!important;
    justify-content: center!important;
}
.tx-left {
    text-align: left;
}
.tx-dark {
    color: #001737;
}

.mg-b-5 {
    margin-bottom: 5px;
}

.bg-light h3, h3 {
    font-size: 1.75rem;
        margin-bottom: 0.5rem;
    font-weight: 500;
    line-height: 1.25;
    color: #021222;
}
.btn-block {
  width:100%;
}